body {
  margin: 0;
}

.cl2{
  width: 49%;
  display: inline-flex;
}

#linear-1-1 {
  background-color: rgb(225,225,225) !important; 
}

#linear-1-1 :first-child {
  background-color:rgb(148,0,6) !important;
}

#linear-1-2 {
  background-color: rgb(225,225,225) !important;
}

#linear-1-2 :first-child {
  background-color:rgb(148,0,6) !important;
}

#linear-2-1 {
  background-color: rgb(225,225,225) !important;
}

#linear-2-1 :first-child {
  background-color:rgb(148,0,6) !important;
}

div.MuiLinearProgress-colorPrimary-288 {
  background-color: rgb(225,225,225) !important;
}

div.MuiLinearProgress-colorPrimary-288 :first-child {
  background-color:rgb(148,0,6) !important;
}

div.MuiLinearProgress-colorSecondary-289 { 
  background-color: rgb(225,225,225) !important;
}

div.MuiLinearProgress-barColorSecondary-299 {
  background-color:rgb(148,0,6) !important;
}

div.MuiLinearProgress-colorPrimary-330  {
  background-color: rgb(225,225,225) !important;
}

div.MuiLinearProgress-colorPrimary-330 :first-child {
  background-color:rgb(148,0,6) !important;
}


